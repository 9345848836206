<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="下单时间">
              <el-date-picker
                style="width: 100%"
                clearable
                v-model="range"
                type="daterange"
                format="yyyy - MM - dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('userName')">
              <el-input
                clearable
                placeholder="手机号/姓名"
                v-model="form.searchCon"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="运动项目">
              <el-select
                placeholder="请选择"
                clearable
                v-model="form.sportsProjectCode"
                style="width: 100%"
              >
                <el-option
                  v-for="item in sportList"
                  :key="item.sportsProjectCode"
                  :label="item.sportsProjectName"
                  :value="item.sportsProjectCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="订单来源">
              <el-select
                placeholder="请选择"
                clearable
                v-model="form.orderFrom"
                style="width: 100%"
              >
                <el-option
                  v-for="item in orderList"
                  :key="item.value"
                  :label="item.lable"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="text-align: right">
            <el-button @click="searchEvent" class="search-button">{{
              $t("search")
            }}</el-button>
            <el-button @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="支付类型">
              <el-select
                placeholder="请选择"
                clearable
                v-model="form.payType"
                style="width: 100%"
              >
                <el-option
                  v-for="item in payTypeList"
                  :key="item.code"
                  :label="item.label"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="search-statue">
          <div
            style="
              display: inline-block;
              color: #606266;
              margin-left: 10px;
              margin-right: 10px;
              font-size: 14px;
            "
          >
            订单状态
          </div>
          <div
            style="display: inline-block; margin: 0 10px"
            v-for="(item, index) in statusData"
            :key="index"
          >
            <el-button
              style="border: 1px solid #439bff; background: #439bff"
              :class="{ unchose: form.orderStatusText !== item }"
              @click="tabClick(index)"
              round
              >{{ item }}</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="tabs-container">
      <el-tabs
        class="taps-card"
        v-model="form.orderType"
        @tab-click="searchEvent"
      >
        <el-tab-pane label="全部" name="0"></el-tab-pane>
        <el-tab-pane label="预约" name="1"></el-tab-pane>
        <el-tab-pane label="购票" name="2"></el-tab-pane>
        <el-tab-pane label="课程" name="3"></el-tab-pane>
        <el-tab-pane label="赛事" name="4"></el-tab-pane>
        <el-tab-pane label="会员卡" name="6"></el-tab-pane>
        <el-tab-pane label="活动" name="7"></el-tab-pane>
        <el-tab-pane label="商品" name="9"></el-tab-pane>
        <!-- 5---视频课程 -->
      </el-tabs>
    </div>
    <div class="table-container">
      <!-- 1-预约-and-2-购票 -->
      <el-table
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
        v-if="form.orderType === '1' || form.orderType === '2'"
        :data="tableData"
        :height="tableHeight - 90"
        style="width: 100%"
        border
        @expand-change="expandChange"
        :row-class-name="setClassName"
        @sort-change="sort_post_change"
        @selection-change="handleSelectionChange"
        :key="form.orderType === '1' ? '预约' : '购票'"
      >
        <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="props.row.childrenData"
                border>
                <el-table-column
                    prop="date"
                    v-if="props.row.orderType === '预约'"
                    show-overflow-tooltip
                    align="center"
                    label="预约日期">
                </el-table-column>
                <el-table-column
                    prop="spaceName"
                    v-if="props.row.orderType === '预约'"
                    align="center"
                    show-overflow-tooltip
                    label="预约场地">
                </el-table-column>
                <el-table-column
                    prop="timeSlot"
                    v-if="props.row.orderType === '预约'"
                    align="center"
                    show-overflow-tooltip
                    label="预约时段">
                </el-table-column>
                <el-table-column
                    prop="ticketInfo"
                    v-if="props.row.orderType === '票务'"
                    align="center"
                    show-overflow-tooltip
                    label="票务信息">
                </el-table-column>
                <el-table-column
                    prop="eventName"
                    v-if="props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="活动名称">
                </el-table-column>
                <el-table-column
                    prop="eventGroupName"
                    v-if="props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="活动组别名称">
                </el-table-column>
                <el-table-column
                    prop="eventType"
                    v-if="props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="活动类型">
                </el-table-column>
                <el-table-column
                    prop="qty"
                    v-if="props.row.orderType === '票务' || props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="数量">
                </el-table-column>
                <el-table-column
                    prop="time"
                    v-if="props.row.orderType === '票务' || props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="消费时段">
                    <template slot-scope="scope">
                      {{scope.row.startTime}} - {{scope.row.endTime}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="deadline"
                    v-if="props.row.orderType === '票务'"
                    align="center"
                    show-overflow-tooltip
                    label="截止日期">
                </el-table-column>
                <el-table-column
                    prop="ticketNo"
                    align="center"
                    show-overflow-tooltip
                    label="二维码">
                </el-table-column>
                <el-table-column
                    prop="businessRemark"
                    align="center"
                    show-overflow-tooltip
                    label="商家备注">
                </el-table-column>
                <el-table-column
                    prop="price"
                    align="center"
                    show-overflow-tooltip
                    label="价格（元）">
                </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column type="selection" align="center" width="80">
        </el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          label="运动项目"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderType"
          align="center"
          label="订单类型"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单号"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTime"
          label="下单时间"
          sortable="custom"
          min-width="170"
        >
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="payTime"
          sortable="custom"
          min-width="177"
          :sort-method="(a, b) => sortPayTime(a, b, 'payTime')"
          label="支付日期"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customNam"
          label="客户姓名"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customPhone"
          label="联系电话"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="originaPice"
          sortable="custom"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'realPay')"
          label="原价"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="payType"
          min-width="100"
          label="支付类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.payType | payTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberCardPayAmount"
          min-width="100"
          label="卡付金额"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponPrice"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'couponPrice')"
          label="优惠金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="realPay"
          sortable="custom"
          min-width="120"
          :sort-method="(a, b) => sortMoney(a, b, 'originaPice')"
          label="实付"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponName"
          show-overflow-tooltip
          label="优惠券"
          min-width="130"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderStatus"
          label="订单状态"
          min-width="130"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.orderStatus === '待使用'"
              style="color: #eaa92e"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待支付'"
              style="color: #d2514b"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已完成'"
              style="color: #439bff"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待评价'"
              style="color: #47dbc6"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              style="color: rgb(62, 103, 174)"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消(逾期)'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTicketNo"
          show-overflow-tooltip
          label="二维码"
          min-width="200"
        >
        </el-table-column>
        <el-table-column align="left" width="260" :label="$t('option')">
          <template slot-scope="scope">
            <el-button
              class="btn-detail"
              icon="el-icon-s-order"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              title="详情"
              @click="changeAddDialog(true, true, scope.row)"
            ></el-button>
            <el-button
              class="btn-edit btn-order"
              title="添加备注"
              icon="el-icon-edit-outline"
              v-if="buttonAuth.includes('venueOperation:orderRecord:mark')"
              @click="
                openRemarkDialog({
                  ...scope.row,
                })
              "
            ></el-button>
            <el-button
              class="btn-detail"
              title="退款"
              :disabled="
                scope.row.orderStatus === '待支付' ||
                scope.row.orderStatus === '已退款' ||
                (scope.row.orderStatusValue === '99' &&
                  scope.row.cancelReason !== '3') ||
                scope.row.returnFlag === 'N' ||
                scope.row.payType === '5' ||
                scope.row.payType === '4' ||
                scope.row.payType === '2' ||
                scope.row.payType === '6'
              "
              style="color: #d2514b"
              icon="el-icon-s-fold"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              @click="openRefundDialog([scope.row.id], 0)"
            ></el-button>
            <!-- 奥体V1.0.3注释，该功能与此版本的支付逻辑不一致 -->
            <!-- <el-button
              type="text"
              title="去支付"
              class="btn-detail"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              style="cursor: pointer; font-size: 16px"
              @click="toPay(scope.row)"
              ><img src="@/assets/images/pay.png" style="width: 18px"
            /></el-button> -->
            <!-- <span
              type="text"
              class="btn-detail"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              style="cursor: pointer; font-size: 16px"
              @click="toExit(scope.row)"
              >取消</span
            > -->
            <el-button
              class="btn-detail"
              icon="el-icon-circle-close"
              size="mini"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              :title="$t('cancel')"
              style="font-size: 21px"
              @click="toExit(scope.row)"
            ></el-button>
            <!-- <el-button class="btn-delete" icon="el-icon-error" v-if="(scope.row.orderStatusValue === '1' || scope.row.orderStatusValue === '2') && buttonAuth.includes('venueOperation:orderRecord:close')" @click="changeAddDialog(true, false, scope.row)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 6-会员卡 -->
      <el-table
        v-else-if="form.orderType === '6'"
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
        :height="tableHeight - 90"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        key="会员卡"
      >
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column type="selection" align="center" width="80">
        </el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          label="运动项目"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderType"
          align="center"
          label="订单类型"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单号"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTime"
          label="下单时间"
          sortable="custom"
          min-width="170"
        >
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="payTime"
          sortable="custom"
          min-width="177"
          :sort-method="(a, b) => sortPayTime(a, b, 'payTime')"
          label="支付日期"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customNam"
          label="客户姓名"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customPhone"
          label="联系电话"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="originaPice"
          sortable="custom"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'realPay')"
          label="原价"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="payType"
          min-width="100"
          label="支付类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.payType | payTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberCardPayAmount"
          min-width="100"
          label="卡付金额"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponPrice"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'couponPrice')"
          label="优惠金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="realPay"
          sortable="custom"
          min-width="120"
          :sort-method="(a, b) => sortMoney(a, b, 'originaPice')"
          label="实付"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponName"
          show-overflow-tooltip
          label="优惠券"
          min-width="130"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderStatus"
          label="订单状态"
          min-width="130"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.orderStatus === '待使用'"
              style="color: #eaa92e"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待支付'"
              style="color: #d2514b"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已完成'"
              style="color: #439bff"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待评价'"
              style="color: #47dbc6"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              style="color: rgb(62, 103, 174)"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消(逾期)'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTicketNo"
          show-overflow-tooltip
          label="二维码"
          min-width="200"
        >
        </el-table-column>
        <el-table-column align="left" min-width="180" :label="$t('option')">
          <template slot-scope="scope">
            <el-button
              class="btn-detail"
              icon="el-icon-s-order"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              title="详情"
              @click="changeAddDialog(true, true, scope.row)"
            ></el-button>
            <el-button
              class="btn-edit btn-order"
              title="添加备注"
              icon="el-icon-edit-outline"
              v-if="buttonAuth.includes('venueOperation:orderRecord:mark')"
              @click="
                openRemarkDialog({
                  ...scope.row,
                })
              "
            ></el-button>
            <!-- <el-button
              class="btn-detail"
              title="退款"
              :disabled="
                scope.row.orderStatus === '待支付' ||
                scope.row.orderStatus === '已退款' ||
                (scope.row.orderStatusValue === '99' &&
                  scope.row.cancelReason !== '3') ||
                scope.row.returnFlag === 'N' ||
                scope.row.payType === '5' ||
                scope.row.payType === '4' ||
                scope.row.payType === '2' ||
                scope.row.payType === '6'
              "
              style="color: #d2514b"
              icon="el-icon-s-fold"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              @click="openRefundDialog([scope.row.id], 0)"
            ></el-button> -->
            <!-- <el-button
              type="text"
              class="btn-detail"
              title="去支付"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              style="cursor: pointer; font-size: 16px"
              @click="toPay(scope.row)"
              ><img src="@/assets/images/pay.png" style="width: 18px"
            /></el-button> -->
            <el-button
              class="btn-detail"
              icon="el-icon-circle-close"
              size="mini"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              style="font-size: 21px"
              :title="$t('cancel')"
              @click="toExit(scope.row)"
            ></el-button>
            <!-- <el-button class="btn-delete" icon="el-icon-error" v-if="(scope.row.orderStatusValue === '1' || scope.row.orderStatusValue === '2') && buttonAuth.includes('venueOperation:orderRecord:close')" @click="changeAddDialog(true, false, scope.row)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- and-3-课程-and-4-赛事 -->
      <el-table
        v-else-if="form.orderType === '3' || form.orderType === '4'"
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
        :height="tableHeight - 90"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        @expand-change="expandChange"
        :row-class-name="setClassName"
        @selection-change="handleSelectionChange"
        :key="form.orderType === '3' ? '课程' : '赛事'"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :data="props.row.childrenData"
              border>
              <el-table-column
                  prop="date"
                  v-if="props.row.orderType === '预约'"
                  show-overflow-tooltip
                  align="center"
                  label="预约日期">
              </el-table-column>
              <el-table-column
                  prop="spaceName"
                  v-if="props.row.orderType === '预约'"
                  align="center"
                  show-overflow-tooltip
                  label="预约场地">
              </el-table-column>
              <el-table-column
                  prop="timeSlot"
                  v-if="props.row.orderType === '预约'"
                  align="center"
                  show-overflow-tooltip
                  label="预约时段">
              </el-table-column>
              <el-table-column
                  prop="ticketInfo"
                  v-if="props.row.orderType === '票务'"
                  align="center"
                  show-overflow-tooltip
                  label="票务信息">
              </el-table-column>
              <el-table-column
                  prop="eventName"
                  v-if="props.row.orderType === '活动'"
                  align="center"
                  show-overflow-tooltip
                  label="活动名称">
              </el-table-column>
              <el-table-column
                  prop="eventGroupName"
                  v-if="props.row.orderType === '活动'"
                  align="center"
                  show-overflow-tooltip
                  label="活动组别名称">
              </el-table-column>
              <el-table-column
                  prop="eventType"
                  v-if="props.row.orderType === '活动'"
                  align="center"
                  show-overflow-tooltip
                  label="活动类型">
              </el-table-column>
              <el-table-column
                  prop="qty"
                  v-if="props.row.orderType === '票务' || props.row.orderType === '活动'"
                  align="center"
                  show-overflow-tooltip
                  label="数量">
              </el-table-column>
              <el-table-column
                  prop="time"
                  v-if="props.row.orderType === '票务' || props.row.orderType === '活动'"
                  align="center"
                  show-overflow-tooltip
                  label="消费时段">
                  <template slot-scope="scope">
                    {{scope.row.startTime}} - {{scope.row.endTime}}
                  </template>
              </el-table-column>
              <el-table-column
                  prop="deadline"
                  v-if="props.row.orderType === '票务'"
                  align="center"
                  show-overflow-tooltip
                  label="截止日期">
              </el-table-column>
              <el-table-column
                  prop="ticketNo"
                  align="center"
                  show-overflow-tooltip
                  label="二维码">
              </el-table-column>
              <el-table-column
                  prop="businessRemark"
                  align="center"
                  show-overflow-tooltip
                  label="商家备注">
              </el-table-column>
              <el-table-column
                  prop="price"
                  align="center"
                  show-overflow-tooltip
                  label="价格（元）">
              </el-table-column>
          </el-table>
        </template>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column type="selection" align="center" width="80">
        </el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          label="运动项目"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderType"
          align="center"
          label="订单类型"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单号"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTime"
          label="下单时间"
          sortable="custom"
          min-width="170"
        >
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="payTime"
          sortable="custom"
          min-width="177"
          :sort-method="(a, b) => sortPayTime(a, b, 'payTime')"
          label="支付日期"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customNam"
          label="客户姓名"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customPhone"
          label="联系电话"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="originaPice"
          sortable="custom"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'realPay')"
          label="原价"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="payType"
          min-width="100"
          label="支付类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.payType | payTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberCardPayAmount"
          min-width="100"
          label="卡付金额"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponPrice"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'couponPrice')"
          label="优惠金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="realPay"
          sortable="custom"
          min-width="120"
          :sort-method="(a, b) => sortMoney(a, b, 'originaPice')"
          label="实付"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponName"
          show-overflow-tooltip
          label="优惠券"
          min-width="130"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderStatus"
          label="订单状态"
          min-width="130"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.orderStatus === '待使用'"
              style="color: #eaa92e"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待支付'"
              style="color: #d2514b"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已完成'"
              style="color: #439bff"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待评价'"
              style="color: #47dbc6"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              style="color: rgb(62, 103, 174)"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消(逾期)'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTicketNo"
          show-overflow-tooltip
          label="二维码"
          min-width="200"
        >
        </el-table-column>
        <el-table-column align="left" min-width="300" :label="$t('option')">
          <template slot-scope="scope">
            <el-button
              class="btn-detail"
              icon="el-icon-s-order"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              title="详情"
              @click="changeAddDialog(true, true, scope.row)"
            ></el-button>
            <el-button
              class="btn-edit btn-order"
              title="添加备注"
              icon="el-icon-edit-outline"
              v-if="buttonAuth.includes('venueOperation:orderRecord:mark')"
              @click="
                openRemarkDialog({
                  ...scope.row,
                })
              "
            ></el-button>
            <!-- 课程或赛事不允许退款 -->
            <!-- <el-button
              class="btn-detail"
              title="退款"
              disabled
              style="color: #d2514b"
              icon="el-icon-s-fold"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              @click="openRefundDialog([scope.row.id], 0)"
            ></el-button> -->
            <!-- <el-button
              class="btn-detail"
              title="退款"
              :disabled=" false && scope.row.orderStatus === '待支付' ||
                scope.row.orderStatus === '已退款' ||
                (scope.row.orderStatusValue === '99' &&
                  scope.row.cancelReason !== '3') ||
                scope.row.returnFlag === 'N' ||
                scope.row.payType === '5' ||
                scope.row.payType === '4' ||
                scope.row.payType === '2'
              "
              style="color: #d2514b"
              icon="el-icon-s-fold"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              @click="openRefundDialog([scope.row.id], 0)"
            ></el-button> -->
            <!-- <el-button class="btn-delete" icon="el-icon-error" v-if="(scope.row.orderStatusValue === '1' || scope.row.orderStatusValue === '2') && buttonAuth.includes('venueOperation:orderRecord:close')" @click="changeAddDialog(true, false, scope.row)"></el-button> -->
            <!-- <el-button
              type="text"
              class="btn-detail"
              title="去支付"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              style="cursor: pointer; font-size: 16px"
              @click="toPay(scope.row)"
              ><img src="@/assets/images/pay.png" style="width: 18px"
            /></el-button> -->
            <el-button
              class="btn-detail"
              icon="el-icon-circle-close"
              size="mini"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              style="font-size: 21px"
              :title="$t('cancel')"
              @click="toExit(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 0：全部 -->
      <el-table
        v-else
        :data="tableData"
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
        :height="tableHeight - 90"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        @expand-change="expandChange"
        :row-class-name="setClassName"
        @selection-change="handleSelectionChange"
        key="全部"
      >
        <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="props.row.childrenData"
                border>
                <el-table-column
                    prop="date"
                    v-if="props.row.orderType === '预约'"
                    show-overflow-tooltip
                    align="center"
                    label="预约日期">
                </el-table-column>
                <el-table-column
                    prop="spaceName"
                    v-if="props.row.orderType === '预约'"
                    align="center"
                    show-overflow-tooltip
                    label="预约场地">
                </el-table-column>
                <el-table-column
                    prop="timeSlot"
                    v-if="props.row.orderType === '预约'"
                    align="center"
                    show-overflow-tooltip
                    label="预约时段">
                </el-table-column>
                <el-table-column
                    prop="ticketInfo"
                    v-if="props.row.orderType === '票务'"
                    align="center"
                    show-overflow-tooltip
                    label="票务信息">
                </el-table-column>
                <el-table-column
                    prop="eventName"
                    v-if="props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="活动名称">
                </el-table-column>
                <el-table-column
                    prop="eventGroupName"
                    v-if="props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="活动组别名称">
                </el-table-column>
                <el-table-column
                    prop="eventType"
                    v-if="props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="活动类型">
                </el-table-column>
                <el-table-column
                    prop="qty"
                    v-if="props.row.orderType === '票务' || props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="数量">
                </el-table-column>
                <el-table-column
                    prop="time"
                    v-if="props.row.orderType === '票务' || props.row.orderType === '活动'"
                    align="center"
                    show-overflow-tooltip
                    label="消费时段">
                    <template slot-scope="scope">
                      {{scope.row.startTime}} - {{scope.row.endTime}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="deadline"
                    v-if="props.row.orderType === '票务'"
                    align="center"
                    show-overflow-tooltip
                    label="截止日期">
                </el-table-column>
                <el-table-column
                    prop="ticketNo"
                    align="center"
                    show-overflow-tooltip
                    label="二维码">
                </el-table-column>
                <el-table-column
                    prop="businessRemark"
                    align="center"
                    show-overflow-tooltip
                    label="商家备注">
                </el-table-column>
                <el-table-column
                    prop="price"
                    align="center"
                    show-overflow-tooltip
                    label="价格（元）">
                </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column type="selection" align="center" width="80">
        </el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          label="运动项目"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderType"
          align="center"
          label="订单类型"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单号"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTime"
          label="下单时间"
          sortable="custom"
          min-width="170"
        >
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="payTime"
          sortable="custom"
          min-width="177"
          :sort-method="(a, b) => sortPayTime(a, b, 'payTime')"
          label="支付日期"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customNam"
          label="客户姓名"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="customPhone"
          label="联系电话"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="originaPice"
          sortable="custom"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'realPay')"
          label="原价"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="payType"
          min-width="100"
          label="支付类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.payType | payTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberCardPayAmount"
          min-width="100"
          label="卡付金额"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponPrice"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'couponPrice')"
          label="优惠金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="realPay"
          sortable="custom"
          min-width="120"
          :sort-method="(a, b) => sortMoney(a, b, 'originaPice')"
          label="实付"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponName"
          show-overflow-tooltip
          label="优惠券"
          min-width="130"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderStatus"
          label="订单状态"
          min-width="130"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.orderStatus === '待使用'"
              style="color: #eaa92e"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待支付'"
              style="color: #d2514b"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已完成'"
              style="color: #439bff"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待评价'"
              style="color: #47dbc6"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              style="color: rgb(62, 103, 174)"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消(逾期)'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTicketNo"
          show-overflow-tooltip
          label="二维码"
          min-width="200"
        >
        </el-table-column>
        <el-table-column align="left" min-width="300" :label="$t('option')">
          <template slot-scope="scope">
            <el-button
              class="btn-detail"
              icon="el-icon-s-order"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              title="详情"
              @click="changeAddDialog(true, true, scope.row)"
            ></el-button>
            <el-button
              class="btn-edit btn-order"
              title="添加备注"
              icon="el-icon-edit-outline"
              v-if="buttonAuth.includes('venueOperation:orderRecord:mark')"
              @click="
                openRemarkDialog({
                  ...scope.row,
                })
              "
            ></el-button>
            <el-button
              class="btn-detail"
              title="退款"
              :disabled="
                scope.row.orderStatus === '待支付' ||
                scope.row.orderStatus === '已退款' ||
                scope.row.orderStatus === '已完成' ||
                (scope.row.orderStatusValue === '99' &&
                  scope.row.cancelReason !== '3') ||
                scope.row.returnFlag === 'N' ||
                scope.row.payType === '5' ||
                scope.row.payType === '4' ||
                scope.row.payType === '2' ||
                scope.row.payType === '6'
              "
              style="color: #d2514b"
              icon="el-icon-s-fold"
              size="mini"
              v-if="buttonAuth.includes('venueOperation:orderRecord:detail')"
              @click="openRefundDialog([scope.row.id], 0)"
            ></el-button>
            <!-- <el-button class="btn-delete" icon="el-icon-error" v-if="(scope.row.orderStatusValue === '1' || scope.row.orderStatusValue === '2') && buttonAuth.includes('venueOperation:orderRecord:close')" @click="changeAddDialog(true, false, scope.row)"></el-button> -->
            <!-- <el-button
              type="text"
              class="btn-detail"
              title="去支付"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              style="cursor: pointer; font-size: 16px"
              @click="toPay(scope.row)"
              ><img src="@/assets/images/pay.png" style="width: 18px"
            /></el-button> -->
            <el-button
              class="btn-detail"
              icon="el-icon-circle-close"
              size="mini"
              v-if="
                scope.row.orderStatus == '待支付' &&
                scope.row.orderFrom == 'venue'
              "
              style="font-size: 21px"
              :title="$t('cancel')"
              @click="toExit(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="sumClass">
      <div class="left">
        <span class="orderNum">总计{{ countNum }}个订单</span>
      </div>
      <div class="right">
        <span v-if="false">总收入￥{{ countAllPrice }}元</span>
      </div>
    </div>
    <div class="page-wrapper">
      <el-button
        class="default-button"
        v-if="buttonAuth.includes('venueOperation:orderRecord:addOrder')"
        @click="goOrder"
        >开单</el-button
      >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
    <!-- <div class="button-container"> -->
    <!-- <el-button class="default-button" @click="openDeleteDialog(selectedArr.map(item => item.userId))">{{$t('delete')}}</!-->
    <!-- </div> -->
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 备注弹窗 -->
    <remark-dialog
      ref="confirmDialog"
      :id="remarkDialog.remarkId"
      :Language="Language"
      :show="remarkDialog.visible"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></remark-dialog>
    <!-- 确认退款弹窗 -->
    <confirm-dialog
      :sureDisabled="confirmDialog.sureDisabled"
      ref="confirmDialog"
      :text="confirmDialog.text"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="onConfirmDialog"
      @closeDialog="closeConfirmDialog"
    ></confirm-dialog>
    <el-dialog title="支付" :visible.sync="isPay" width="700px">
      <el-form :model="payForm" label-width="100px">
        <el-form-item label="支付金额："
          ><span>{{ price }}</span>
        </el-form-item>
        <el-form-item label="支付方式：">
          <el-radio v-model="payForm.payType" label="2" @change="toSelectOther"
            >线下现金</el-radio
          >
          <el-radio v-model="payForm.payType" label="5" @change="toSelectOther"
            >线下微信</el-radio
          >
          <el-radio v-model="payForm.payType" label="4" @change="toSelectOther"
            >线下支付宝</el-radio
          >
          <el-radio v-model="payForm.payType" label="6" @change="toSelectOther"
            >线下刷卡</el-radio
          >
          <el-radio
            v-model="payForm.payType"
            label="3"
            v-if="cardList.length > 0"
            @change="toSelect"
            >会员卡</el-radio
          >
        </el-form-item>
        <el-form-item v-if="isShow">
          <el-radio
            v-for="item in cardList"
            :key="item.id"
            v-model="payForm.memberCardId"
            :label="item.id"
            @change="test"
          >
            <span style="display: inline-block">
              <span>{{ item.cardName }}：</span><span>余额</span
              ><span style="margin-left: 10px">{{ item.remainAmount }}</span>
            </span>
            <span style="margin-left: 18px">折扣：</span
            ><span>{{ item.consumeDiscount }}</span
            ><span style="margin-left: 40px">卡付：</span
            ><span style="margin-left: 10px">{{
              (price * item.consumeDiscount).toFixed(2)
            }}</span></el-radio
          >
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          class="footer-confirm"
          @click="sureEvent"
          :disabled="isDisable"
          >{{ $t("sure") }}</el-button
        >
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="toClose">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
    <!-- 小票弹窗 -->
    <DetailDialog
      ref="detailDialog"
      :show="detailDialog.visible"
      @closeDialog="changeDetailDialog"
    ></DetailDialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
// import confirmDialog from '@/components/confirmDialog.vue'
import addDialog from "./dialog/addDialog.vue";
import remarkDialog from "./dialog/remarkDialog.vue";
import OrderRecordSearchClass from "./orderRecordSearchClass";
import confirmDialog from "@/components/confirmDialogNew.vue";
import DetailDialog from "../order/compoments/detailDialog.vue";

export default {
  components: {
    addDialog,
    remarkDialog,
    confirmDialog,
    DetailDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new OrderRecordSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      pictLoading: true,
      addDialog: {
        visible: false,
        isEdit: false,
      },
      detailDialog: {
        visible: false,
      },
      statusData: ["全部", "待支付", "已支付", "已取消", "已退款"],
      range: [],
      remarkDialog: {
        visible: false,
        remarkId: "",
      },
      invoiceData: "",
      templateRadio: false,
      selectedArr: [],
      countNum: 0,
      countAllPrice: 0,
      confirmDialog: {
        visible: false,
        type: 1,
        text: "",
        data: {},
        sureDisabled: false,
      },
      isPay: false,
      payForm: {
        venueId: "",
        payType: "5",
        id: "",
        memberCardId: "",
      },
      customPhone: "",
      sportsProjectCode: "",
      cardList: [],
      radio: "",
      isShow: false,
      isDisable: false,
      price: "",
      sportList: [],
      orderList: [
        {
          lable: "全部",
          value: "0",
        },
        {
          lable: "线上-小程序",
          value: "1",
        },
        {
          lable: "线下-现场开单",
          value: "2",
        },
      ],
      payTypeList: [],
      typeList: ['预约', '票务', '活动'],
    };
  },
  filters: {
    payTypeFilter(status) {
      const statusMap = {
        0: "免支付",
        1: "线上-微信",
        5: "线下-微信",
        3: "线上-会员卡",
        4: "线下-支付宝",
        2: "线下-现金",
        6: "线下-会员卡",
      };
      return statusMap[status];
    },
  },
  computed: {
    ...mapState(["Language", "venueId", "merchantAccountDetailInfoDTO"]),
  },
  activated() {
    if (this.$route.query.venueId) {
      this.form.finalVenueId = this.$route.query.venueId;
      this.form.orderStatusText = "已支付";
      this.form.searchStatusType = "2";
      this.getSportList(this.form.finalVenueId);
    } else {
      this.getSportList(this.venueId);
      this.form.finalVenueId = this.venueId;
    }
    // this.form.finalVenueId = this.merchantAccountDetailInfoDTO.merchantId
    // console.log(this.merchantAccountDetailInfoDTO.merchantId, 'this.merchantAccountDetailInfoDTO.merchantId');
    if (this.$route.query.type) {
      this.form.orderType = this.$route.query.type;
    }
    this.form.orderFrom = this.$route.query.orderFrom;
    this.form.payType = this.$route.query.payType;
    if (this.$route.query.date) {
      this.range = JSON.parse(this.$route.query.date);
    }
    this.getPaymentType();
  },
  mounted() {},
  methods: {
    getSportList(id) {
      this.$http
        .get(`${apis.getSportsProjectListById}?id=${id}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    getPaymentType() {
      this.$http.get(apis.getPaymentTypes).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          this.payTypeList = res.data.data
        }
      });
    },
    // 展开
    expandChange(row, expandedRows) {
      console.log(row, expandedRows, 123123)
      this.$http.post(apis.orderDetailList, { id: row.id }).then((res) => {
        if (res.data.code === 0) {
          this.tableData.forEach((item, index) => {
            if (item.id === row.id) {
              this.tableData[index].childrenData = res.data.rows || [];
            }
          })
        }
      })
    },
    //设置样式
    setClassName({ row }) {
      console.log(row.hasChildren)
      return row.hasChildren ? '' : 'expand'
    },
    toSelect() {
      this.isShow = true;
    },
    toSelectOther(val) {
      this.payForm.type = val;
      this.payForm.memberCardId = "";
      this.isShow = false;
      this.isDisable = false;
    },
    test(value) {
      console.log(value);
      const data = this.cardList.filter((item) => {
        return item.id === value;
      });
      if (data.length > 0) {
        if (data[0].remainAmount - this.price * data[0].consumeDiscount > 0) {
          this.isDisable = false;
        } else {
          this.isDisable = true;
        }
      }
    },
    toPay(item) {
      this.payForm.payType = "5";
      this.payForm.memberCardId = "";
      this.isShow = false;
      this.customPhone = item.customPhone;
      this.price = item.originaPice;
      this.payForm.id = item.id;
      this.sportsProjectCode = item.sportsProjectCode;
      this.isPay = true;
      if (item.orderType === "预约") this.getCardList(); // 只有预定场馆才有卡付
    },
    toExit(item) {
      this.$http
        .post(apis.appCancelOrder, {
          id: item.id,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.searchData();
          }
        });
    },
    getCardList() {
      const params = {
        tel: this.customPhone,
        venueId: this.venueId,
      };
      this.cardList = [];
      this.$http
        .get(apis.getCardListByTel, {
          params,
        })
        .then((res) => {
          if (res.data.code === 0) {
            console.log("++++++++++++getCardList：");
            if (res.data.data) {
              this.cardList = res.data.data;
            } else {
              this.cardList = [];
            }
          }
        });
    },
    sureEvent() {
      this.payForm.venueId = this.venueId;
      this.$http
        .post(apis.getReservateOrderById, {
          ...this.payForm,
          // id: this.confirmDialog.data.ids.join(","),
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.isPay = false;
            // 调小票弹窗
            this.checkDetail(this.payForm.id);
            this.searchData();
          }
        });
    },
    /**
     * @function 打开小票预览弹窗
     * @param {Array} id 选中的ID数组
     */
    checkDetail(id) {
      this.detailDialog.visible = true;
      this.$refs.detailDialog.getDataQuery(id);
    },
    /**
     * @function 关闭小票预览弹窗
     */
    changeDetailDialog() {
      this.detailDialog.visible = false;
    },
    toClose() {
      this.isPay = false;
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    closeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
      }
    },
    openRefundDialog(id, type) {
      let text = "";
      switch (type) {
        case 0:
          text = "您确认要退款操作吗?";
          break;
        default:
          text = "您确认要删除该数据吗?";
          break;
      }
      if (id.length === 0) {
        this.$message.error("请先选择数据");
        return;
      }
      this.confirmDialog.data.ids = [];
      this.confirmDialog.data.ids = id;
      this.confirmDialog.type = type;
      this.confirmDialog.text = text;
      this.onRefundDialog(true, type);
    },
    onRefundDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    onConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 0) {
        this.confirmDialog.sureDisabled = true;
        this.$http
          .post(apis.ordermanagementRefund, {
            id: this.confirmDialog.data.ids.join(","),
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$refs.confirmDialog.closeDialog();
              const loading = this.$loading({
                lock: false,
                text: "正在加载中.....",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "loading-login",
              });
              this.timer = setTimeout(() => {
                this.$message.success("提交退款，退款进行中!");
                this.searchData();
                loading.close();
              }, 5000);
              this.confirmDialog.sureDisabled = false;
            } else {
              this.confirmDialog.sureDisabled = false;
            }
          });
      }
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      // this.form.finalVenueId = this.venueId;
      if (this.$route.query.venueId) {
        this.form.finalVenueId = this.$route.query.venueId;
        this.form.orderStatusText = "已支付";
        this.form.searchStatusType = "2";
        this.getSportList(this.form.finalVenueId);
      } else {
        this.getSportList(this.venueId);
        this.form.finalVenueId = this.venueId;
      }
      // this.form.finalVenueId = this.merchantAccountDetailInfoDTO.merchantId
      if (this.range.length) {
        [this.form.orderStTime, this.form.orderEndTime] = this.range;
      }
      console.log("获取值", this.form);
      this.pictLoading = true;
      this.tableData = [];
      this.$http.post(apis.queryOrderManagementList, this.form).then((res) => {
        if (res.data.code === 0) {
          console.log("res.data.rows", res.data.rows);
          // this.tableData = res.data.rows;
          this.countNum = res.data.total
          console.log(res.data.total);
          this.tableData = res.data.rows.map((item, index) => {
            item.keyId = index;
            item.hasChildren = this.typeList.includes(item.orderType)
            item.childrenData = []; // 添加子表格数据
            return item;
          })
          console.log(this.tableData);
          this.form.total = res.data.total;
          this.pictLoading = false;
          // v1.0.3原先代码取值错误
          // if (this.tableData.length > 0 && this.tableData[0].countNum) {
          //   this.countNum = this.tableData[0].countNum;
          //   console.log(this.countNum);
          // }
          if (this.tableData.length > 0 && this.tableData[0].countAllPrice) {
            this.countAllPrice = this.tableData[0].countAllPrice;
          }
        } else if (res.data.code === 500) {
          this.tableData = [];
          this.form.total = 0;
        }
      });
    },
    /**
     * @function 表格按照价格排序
     */
    sortMoney(a, b, item) {
      return parseFloat(a[item]) - parseFloat(b[item]);
    },
    sortPayTime(a, b, item) {
      return new Date(a[item]).getTime() - new Date(b[item]).getTime();
    },
    getTemplateRow(index, row) {
      console.log(index);
      this.selectedArr.splice(0, this.selectedArr.length);
      this.selectedArr.push(row);
    },
    /**
     * @function 点击开票跳转效果
     */
    changeInvoice() {
      this.$router.push({
        path: "/venueOperation/order",
        query: { type: this.invoiceData.orderType, id: this.invoiceData.id },
      });
    },
    /**
     * @function tab切换
     */
    tabClick(index) {
      if (index === 0) {
        this.form.searchStatusType = "";
      } else if (index === 1) {
        this.form.searchStatusType = "1";
      } else if (index === 2) {
        this.form.searchStatusType = "2";
      } else if (index === 3) {
        this.form.searchStatusType = "3";
      } else {
        this.form.searchStatusType = "4";
      }
      this.form.orderStatusText = this.statusData[index];
      this.searchData();
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new OrderRecordSearchClass("form");
      this.range = "";
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit;
        this.$refs["addDialog"].getDataQuery(data, isEdit);
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        // this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http
        .post(
          this.confirmDialog.type === 1
            ? `${apis.userRemove}?ids=${this.confirmDialog.data.ids}`
            : apis.resetPassword,
          this.confirmDialog.data,
        )
        .then((res) => {
          if (res.data.code === 0) {
            if (this.confirmDialog.type === 2) {
              this.$confirm(`${this.$t("reset_success")}123456`, "", {
                distinguishCancelAndClose: true,
                confirmButtonText: "确认",
              });
            }
            this.$refs.confirmDialog.closeDialog();
          }
        });
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      this.confirmDialog.data.ids = id.join(",");
      this.changeConfirmDialog(true, 1);
    },
    /**
     * @function 修改备注弹窗
     * @param {Array} data 要重置密码的值
     */
    openRemarkDialog(data, isEdit = false) {
      this.remarkDialog.remarkId = data.id;
      this.remarkDialog.visible = true;
      this.$refs.confirmDialog.getDataQuery(data, isEdit);
    },
    /**
     * @function 改变备注弹窗的状态
     */
    changeConfirmDialog() {
      this.remarkDialog.visible = false;
    },
    /**
     * @function 修改用户启用状态
     * @param {Object} data 用户信息
     */
    changeStatus(data) {
      const form = {
        ...data,
        ...{
          status: data.status === "1" ? "0" : "1",
        },
      };
      this.$http.post(apis.userStatus, form);
    },
    /**
     * @function 跳转到订单页面
     */
    goOrder() {
      if (this.selectedArr.length === 1) {
        this.$router.push({
          path: "order",
          query: {
            user:
              this.selectedArr[0].customPhone === null
                ? "110"
                : this.selectedArr[0].customPhone,
            type: this.selectedArr[0].orderType === "预约" ? "1" : "2",
          },
        });
      } else if (this.selectedArr.length > 1) {
        this.$message.error(`请勿选择多于一条的数据`);
      } else {
        this.$router.push({
          path: "order",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.tabs-container {
  margin: 0 20px;
}
.unchose {
  background: #fff !important;
  color: #439bff !important;
}
.sumClass {
  background: rgba(238, 240, 244, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  // margin-bottom: 10px;
  .left {
  }
  .right {
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-top: 5px;
}
.search-container .el-button {
  // background: #3E67AE !important;
}
.button-container {
  text-align: left;
  .default-btn {
    // float: left !important;
  }
}
.table-container {
  width: calc(100% - 30px);
  margin: 0px auto;
  thead {
    tr {
      th {
        background: #eef0f4 !important;
      }
    }
  }
}
.search-statue {
  .el-button {
    // background: #3E67AE !important;
  }
}
.expand .el-table__expand-column .cell {
  display: none;
}
</style>
