<template>
  <div class="dialog-container">
    <el-dialog
      :title="isEdit ? '详情':'关闭订单'"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-row class="detail">
          <el-col :span="8">
            <div>订单号：</div>
            <div>{{data.orderNum}}</div>
          </el-col>
          <el-col :span="8">
            <div>订单类型：</div>
            <div>{{data.orderType}}</div>
          </el-col>
          <el-col :span="8">
            <div>订单状态：</div>
            <div>
              {{data.orderStatus}}
              <!-- <el-button v-if="data.orderStatus === '待支付'" class="pay-btn" size="mini" type="primary" round @click="goPaying">去支付</el-button> -->
            </div>
          </el-col>
        </el-row>
        <el-row class="detail">
          <el-col :span="8">
            <div>下单日期：</div>
            <div>{{data.orderTime}}</div>
          </el-col>
          <el-col :span="8">
            <div>支付日期：</div>
            <div>{{data.payTime}}</div>
          </el-col>
          <el-col :span="8">
            <template v-if="isEdit">
              <div>取消原因：</div>
              <div>{{data.cancelReason ? data.cancelReason: '未取消'}}</div>
            </template>
            <template v-else>
              <div>取消原因：</div>
              <el-select
                placeholder="请选择"
                clearable
                v-model="opValue"
                style="width: 60%;display:inline-block;"
              >
                <el-option value label="请选择"></el-option>
                <el-option value="1" label="退票或取消预约"></el-option>
                <el-option value="2" label="超时未支付"></el-option>
                <el-option value="3" label="逾期"></el-option>
                <el-option value="4" label="其他"></el-option>
              </el-select>
            </template>
          </el-col>
        </el-row>
        <el-row class="detail">
          <el-col :span="8">
            <div>客户姓名：</div>
            <div>{{data.customerNam}} {{data.customerPhone}}</div>
          </el-col>
          <el-col :span="8">
            <div>原价：</div>
            <div>{{data.originaPice}}</div>
          </el-col>
          <el-col :span="8">
            <div>实付：</div>
            <div>{{data.realPay}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item prop="useRemark" label="用户备注：" label-width="90px">
              <el-input clearable type="textarea" maxlength="100" :rows="3" placeholder v-model="data.customerRemark" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item prop="useRemark" label="商家备注：" label-width="90px">
              <el-input clearable type="textarea" maxlength="100" :rows="3" placeholder v-model="data.businessRemark" :disabled="isEdit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="table-container" v-if="isEdit">
        <div style="text-align:center;margin: 10px auto;font-size:16px;">消费明细</div>
        <!-- 会员卡 -->
        <el-table
          v-if="data.orderType==='会员卡'"
          :data="data.cardInfoVo"
          style="width: 100%;margin-bottom: 20px;"
          border
        >
          <el-table-column prop="memberCardName" label="会员卡名称" min-width="170" align="center" ></el-table-column>
          <el-table-column prop="memberCardType" label="会员卡类型" min-width="170" align="center" >
            <template slot-scope="scope">
              <span>{{scope.row.memberCardType === '1' ? '计时卡' : scope.row.memberCardType === '2' ? '计次卡' : '储值卡'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="rights" label="权益" min-width="170" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="deadline" label="截止日期" min-width="170" align="center" ></el-table-column>
        </el-table>
        <!-- 预约 -->
        <el-table
          v-if="data.orderType==='预约'"
          :data="data.payDetailsList"
          style="width: 100%;margin-bottom: 20px;"
          border
        >
          <el-table-column prop="orderDate" label="预约日期" align="center" ></el-table-column>
          <el-table-column prop="spaceNam" label="预约场地" align="center" ></el-table-column>
          <el-table-column prop="timeSlot" label="预约时段" align="center" ></el-table-column>
          <el-table-column prop="price" label="费用" align="center" ></el-table-column>
        </el-table>
        <!-- 商品 -->
        <el-table
          v-if="data.orderType==='商品'"
          :data="data.productDetailsVOList"
          style="width: 100%;margin-bottom: 20px;"
          border
        >
          <el-table-column prop="productName" label="商品名称" align="center" ></el-table-column>
          <el-table-column prop="productQty" label="数量" align="center" ></el-table-column>
        </el-table>
        <!-- 其他 -->
        <el-table
          v-if="data.orderType && (data.orderType!=='预约' && data.orderType!=='会员卡' && data.orderType!=='商品')"
          :data="data.payDetailsList"
          style="width: 100%;margin-bottom: 20px;"
          border
        >
          <el-table-column prop="tickInfo" label="票务信息" min-width="170" align="center" ></el-table-column>
          <el-table-column prop="tickNum" label="数量" min-width="170" align="center" ></el-table-column>
          <el-table-column prop="orderTime" label="消费时段" min-width="170" align="center" >
            <template slot-scope="scope">
              <span>{{scope.row.startTime}}-{{scope.row.endTime}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deadLine" label="截止日期" min-width="170" align="center" ></el-table-column>
          <el-table-column prop="price" label="单价( 元 )" min-width="170" align="center" ></el-table-column>
        </el-table>
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog" v-if="!isEdit">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
    <!-- 结算弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :typeText="data.orderType" :show="addDialog.visible" :isTicket="addDialog.isTicket" @closeDialog="changeAddDialog" @refesh="refesh"></add-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'
import addDialog from './payDialog.vue'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  components: {
    addDialog,
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      data: {},
      opValue: '',
      id: '',
      addDialog: {
        visible: false,
        isTicket: false,
      },
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      this.data = {}
      console.log('我初始化了')
    },
    /**
     * @function 订单支付成功后，更新当前弹窗
     */
    refesh() {
      this.getDataQuery(this.data, this.data.orderType, true)
    },
    changeAddDialog() {
      this.addDialog.visible = false;
    },
    /**
     * @function 进行订单支付
     */
    goPaying() {
      // this.$refs.addDialog.visible = true;
      this.addDialog.visible = true;
      const type = this.data.orderType === '票务' ? '1' : '2';
      this.$refs.addDialog.getDataQuery(this.data.orderId, type, this.data.payDetailsList);
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.isEdit) {
            const commitData = {
              id: this.id,
              businessRemark: this.data.businessRemark,
              remark: this.opValue,
            }
            this.$http.post(apis.closeOrder, commitData).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
                this.opValue = ''
              }
            })
          } else {
             this.closeDialog()
          }
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data, type, isPayAfter = false) {
      let remark
      this.id = isPayAfter ? data.orderId : data.id
      if (type) {
        remark = 1
      } else {
        remark = 2
      }
      const info = {
        id: isPayAfter ? data.orderId : data.id,
        remark,
      }
      // 在这里获取回显的数据
      this.$http.post(apis.orderDetails, info).then((res) => {
        if (res.data.code === 0) {
          this.data = res.data.rows
        }
      })
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.detail {
  margin: 20px 0;
  div {
    display: inline-block;
    // text-align: center;
  }
}
.pay-btn {
  margin-left: 10px;
}
</style>
